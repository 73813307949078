// var mySwiper = new Swiper('.swiper-container', {
//     loop: true, // 循环模式选项
//     // 如果需要前进后退按钮
//     navigation: {
//         nextEl: '.swiper-right',
//         prevEl: '.swiper-left'
//     }
// })
var onToggleMore = function () {
    document.getElementById('more').classList.toggle('hidden')
    document.getElementById('open').classList.toggle('hidden')
    document.getElementById('close').classList.toggle('hidden')
    if (!document.getElementById('more').classList.contains('hidden')) {
        document.body.style.overflow = 'hidden'
    } else {
        document.body.style.overflow = 'unset'
    }
}

var linkDic = {
    ios: 'https://apps.apple.com/us/app/tanglepay-iota-wallet/id1591026253',
    tf: 'https://testflight.apple.com/join/AsS8Akbv',
    android: 'https://play.google.com/store/apps/details?id=com.cat.iota',
    apk: window.location.origin + '/download/TanglePay-Mobile-V1.6.4.apk?time=' + new Date().getTime(),
    chrome: 'https://chrome.google.com/webstore/detail/tanglepay-iota-wallet/hbneiaclpaaglopiogfdhgccebncnjmc?hl=en-US'
}
// 根据设备判断跳转url
function download(type) {
    if (!type) {
        if (device.ios()) {
            type = 'ios'
        } else if (device.android()) {
            type = 'android'
        } else {
            type = 'chrome'
        }
    }
    if (linkDic[type]) {
        window.open(linkDic[type])
    }
}
/// 锚点
window.anchorClick = function (id) {
    var jump = document.getElementById(id)
    if (jump) {
        window.scrollTo({ top: jump.offsetTop - 20, behavior: 'smooth' })
    }
}
